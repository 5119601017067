import React, { useState } from "react";
import "../Human/Human.css";
// import anat from "../../../public/images/Anat.png";
// import anat3 from "../../../public/images/Anat3.png";
// import anat2 from "../../../public/images/Anat2.png";
// import sweat from "../../../public/images/Group.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

export default function Human() {
  const [animateStep, setAnimateStep] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  
 

 
  const handleNextClick = () => {
    setAnimateStep((prevStep) => (prevStep + 1) % 7);
  };

  const handlePrevClick = () => {
    setAnimateStep((prevStep) => (prevStep - 1) % 7);
  };

  const animations = [
    { opacity: [0, 1], x: [0, 0], y: [0, 0], scale: [0.3, 1] },
    { scale: [1, 4], y: [0, 450] },
    { y: [450, 350], scale: 4 },
    {
      scale: [4, 4.6],
    },
    { y: [450, 350], scale: 4 },
    {
      scale: [4, 4.6],
    },
    {
      scale: [2, 3],
      y: [300, 450],
    },
  ];

  const contentAnimations = [
    {
      text: {},
      arrow: {},
    },
    {
      text: {
        opacity: 1,
        scale: [0, 1],
        y: [0, 160],
        x: [0, 770],
      },
      arrow: {
        opacity: 1,
        scale: [0, 1],
        y: [0, 0],
        x: [-300, 0],
      },
    },
    {
      text: {
        opacity: 1,
        scale: [0, 1],
        y: [0, 435],
        x: [0, 670],
      },
      arrow: {
        opacity: 1,
        scale: [0, 1],
        y: [0, 0],
        x: [-10, 0],
      },
    },
    {
      text: {
        opacity: 1,
        scale: [0, 1],
        y: [0, 95],
        x: [0, 700],
      },
      arrow: {
        opacity: 1,
      },
    },

    {
      text: {
        opacity: 1,
        scale: [0, 1],
        y: [0, 335],
        x: [0, 770],
      },
      arrow: {
        opacity: 1,
      },
    },
    {
      text: {
        opacity: 1,
        scale: [0, 1],
        y: [0, 185],
        x: [0, 770],
      },
      arrow: {
        opacity: 1,
      },
    },
    {
      text: {
        opacity: [0,0,0,1],
        scale: [0, 1],
        y: [0, 200],
        x: [0, 570],
      },
      arrow: {
        opacity: [0,0,0,1],
      },
    },
  ];
  


  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="boxNext">
              <div className="moonBox2">
                <div>
                  {animateStep === 1 ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={contentAnimations[animateStep].text}
                        transition={{ duration: 1 }}
                      >
                        <h2>Chest Pain</h2>
                      </motion.div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={contentAnimations[animateStep].arrow}
                        transition={{ duration: 1 }}
                      >
                        <div className="line"></div>
                        <div className="circle  "></div>
                      </motion.div>
                    </>
                  ) : (
                    ""
                  )}

                  {animateStep === 2 ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={contentAnimations[animateStep].text}
                        transition={{ duration: 1 }}
                      >
                        <h2>Upper Belly Pain</h2>
                      </motion.div>
                      <motion.div
                        initial={{ opacity: [0] }}
                        animate={contentAnimations[animateStep].arrow}
                        transition={{ duration: 1 }}
                      >
                        <div className="lineUpper"></div>
                        <div className="circleUpper "></div>
                      </motion.div>
                    </>
                  ) : (
                    ""
                  )}

                  {animateStep === 3 ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={contentAnimations[animateStep].text}
                        transition={{ duration: 1 }}
                      >
                        <h2>Shoulder Pain</h2>
                      </motion.div>
                      <motion.div
                        initial={{ opacity: [0] }}
                        animate={contentAnimations[animateStep].arrow}
                        transition={{ duration: 0.5 }}
                      >
                        <div className="lineShoulder"></div>
                        <div className="circleShoulder"></div>
                      </motion.div>
                    </>
                  ) : (
                    ""
                  )}

                  {animateStep === 4 ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={contentAnimations[animateStep].text}
                        transition={{ duration: 1 }}
                      >
                        <h2>Arm Pain</h2>
                      </motion.div>
                      <motion.div
                        initial={{ opacity: [0] }}
                        animate={contentAnimations[animateStep].arrow}
                        transition={{ duration: 0.5 }}
                      >
                        <div className="lineArm"></div>
                        <div className="circleArm"></div>
                      </motion.div>
                    </>
                  ) : (
                    ""
                  )}

                  {animateStep === 5 ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={contentAnimations[animateStep].text}
                        transition={{ duration: 1 }}
                      >
                        <h2>Back Pain</h2>
                      </motion.div>
                      <motion.div
                        initial={{ opacity: [0] }}
                        animate={contentAnimations[animateStep].arrow}
                        transition={{ duration: 0.5 }}
                      >
                        <div className="lineBack"></div>
                        <div className="circleBack"></div>
                      </motion.div>
                    </>
                  ) : (
                    ""
                  )}

                  {animateStep === 6 ? (
                    <>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={contentAnimations[animateStep].text}
                        transition={{ duration: 1 }}
                      >
                        <h2>Sudden, heavy sweating</h2>
                      </motion.div>
                      <motion.img
                        src={process.env.PUBLIC_URL + '/images/Group.png'}
                        className="lineSweat"
                        initial={{ opacity: [0] }}
                        animate={contentAnimations[animateStep].arrow}
                        transition={{ duration: 1 }}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  <div className="contentPhoto">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      className="icon"
                      onClick={handlePrevClick}
                    />
                    {animateStep === 0 ||
                    animateStep === 1 ||
                    animateStep === 2 ? (
                      <motion.img
                        animate={animations[animateStep]}
                        transition={{ duration: 1 }}
                        className="w-75"
                        src={process.env.PUBLIC_URL + '/images/Anat.png'}
                        alt=""
                      />
                    ) : (
                      ""
                    )}

                    {animateStep === 3 || animateStep === 4 ? (
                      <motion.img
                        initial={{ scale: 4, y: 500, x: -150 }}
                        animate={animations[animateStep]}
                        transition={{ duration: 1 }}
                        className="w-75  "
                        src={process.env.PUBLIC_URL + '/images/Anat2.png'}
                        alt=""
                      />
                    ) : (
                      ""
                    )}

                    {animateStep === 5 ? (
                      <motion.img
                        initial={{ scale: 4, y: 500, x: -150 }}
                        animate={animations[animateStep]}
                        transition={{ duration: 1 }}
                        className="w-75  "
                        src={process.env.PUBLIC_URL + '/images/Anat3.png'}
                        alt=""
                      />
                    ) : (
                      ""
                    )}

                    {animateStep === 6 ? (
                      <motion.img
                        initial={{ scale: 2, y: 300, x: -100 }}
                        animate={animations[animateStep]}
                        transition={{ duration: 1 }}
                        className="w-75  "
                        src={process.env.PUBLIC_URL + '/images/Anat2.png'}
                        alt=""
                      />
                    ) : (
                      ""
                    )}

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="icon"
                      onClick={handleNextClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


  

  // useEffect(() => {
  //   const preloadImages = async () => {
  //     const imageSources = [anat, anat2, anat3, sweat];
  //     const promises = imageSources.map((src) => {
  //       return new Promise((resolve, reject) => {
  //         const img = new Image();
  //         img.src = src;
  //         img.onload = resolve;
  //         img.onerror = reject;
  //       });
  //     });

  //     await Promise.all(promises);
  //     setImagesLoaded(true);
  //   };

  //   preloadImages();
  // }, []);

  // if (!imagesLoaded) {
  //   return <div>Loading...</div>;
  // }