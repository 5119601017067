
import './App.css';
import Home from './Components/Home/Home';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import NextAnimation from './Components/NextAnimation/NextAnimation';
import AnimateRotate from './Components/AnimateRotate/AnimateRotate';
import RotateAnimation from './Components/RotateAnimation/RotateAnimation';
import NewRotate from './Components/NewRotate/NewRotate';
import FoodRotate from './Components/FoodRotate/FoodRotate';
import Human from './Components/Human/Human';
import "@fortawesome/react-fontawesome"



function App() {

  // if ('serviceWorker' in navigator) {
  //   window.addEventListener('load', () => {
  //     navigator.serviceWorker.register('./service-worker.js').then((registration) => {
  
  //       console.log('ServiceWorker registration successful with scope: ', registration.scope);
  //     }, (error) => {
  //       console.log('ServiceWorker registration failed: ', error);
  //     });
  //   });
  // }


  
  return <>
   {/* <Home/> */}

   {/* <NextAnimation/> */}

   {/* <AnimateRotate/> */}
   {/* <RotateAnimation/> */}

   {/* <NewRotate/> */}

   {/* <FoodRotate/> */}

   <Human/>
   


  </>
   
 
}

export default App;
